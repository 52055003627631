import * as React from 'react';

import clsx from 'clsx';
import emailjs from '@emailjs/browser';
import { Link } from 'gatsby';

export interface ContactFormProps
  extends React.ComponentPropsWithoutRef<'div'> {
  label?: React.ReactNode;
}

export default function ContactForm({
  label,
  className,
  ...props
}: ContactFormProps) {
  const formRef = React.useRef<HTMLFormElement | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleChangeCheckbox: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    const input = document.querySelector(
      `label[for="${e.target.id}"].w-checkbox > div.w-checkbox-input`,
    );
    if (!input) return;

    if (e.target.checked) {
      input.classList.add('w--redirected-checked');
    } else {
      input.classList.remove('w--redirected-checked');
    }
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (formRef.current) {
      try {
        setLoading(true);
        await emailjs.sendForm('mosano-ses', 'mosano_contact', formRef.current);
        formRef.current.reset();

        // Fire a custom event after successful form submission
        window.dataLayer.push({
          event: 'formSubmissionSuccess',
          eventCategory: 'form',
          eventAction: 'submit',
          eventLabel: 'Your Digital Partner Landing Page',
          eventValue: 1
        });
        
        window.location.assign('https://mosano.eu/thanks?yourdigitalparnter');
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div
      id="contact-form"
      data-w-id="07875213-2ee7-f39f-5578-f1faecdf2509"
      className={clsx('wp-form', className)}
      {...props}
    >
      {label && (
        <h3 className="h2 center" style={{ marginBottom: 24 }}>
          {label}
        </h3>
      )}
      <div
        id="wf-form-content"
        data-name="Contact Form"
        className="form w-form"
      >
        <form
          ref={formRef}
          id="form_landingPage"
          name="wf-form-Contact-Form"
          data-name="Contact Form"
          method="get"
          className="form-text w-clearfix"
          onSubmit={handleSubmit}
          aria-disabled={loading}
        >
          <div className="form-name">
            <label htmlFor="name" id="name-label" className="form-label">
              Name
            </label>
            <input
              type="text"
              className="form-field w-input"
              maxLength={256}
              name="name"
              data-name="name"
              placeholder=""
              id="name"
              required
              autoComplete="name"
              readOnly={loading}
            />
          </div>
          <div className="wp-form-contacts">
            <div className="form-email">
              <label htmlFor="email" id="email-label" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-field w-input"
                maxLength={256}
                name="email"
                data-name="email"
                placeholder=""
                id="email"
                required
                autoComplete="email"
                readOnly={loading}
              />
            </div>
            <div className="form-phone">
              <label htmlFor="phone" id="phone-label" className="form-label">
                Phone (optional)
              </label>
              <input
                type="tel"
                className="form-field w-input"
                maxLength={256}
                name="phone"
                data-name="phone"
                placeholder=""
                id="phone"
                autoComplete="tel"
                readOnly={loading}
              />
            </div>
          </div>
          <div className="form-about">
            <label htmlFor="about" id="about-label" className="form-label">
              Message
            </label>
            <textarea
              className="form-field w-input"
              maxLength={256}
              name="about"
              data-name="about"
              placeholder=""
              id="about"
              required
              readOnly={loading}
            />
          </div>
          {/* <div className="form-budget">
            <p id="expected-budget" className="form-label roles">
              What roles (optional)
            </p>
            <div className="form-budget-options">
              <div className="wp-accordion">
                <div className="wp-accordion-item">
                  <label
                    id="Full-stack-item"
                    htmlFor="fullstack"
                    className="w-checkbox wp-accordion-trigger"
                  >
                    <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox"></div>
                    <input
                      type="checkbox"
                      id="fullstack"
                      name="fullstack"
                      data-name="fullstack"
                      readOnly={loading}
                      style={{
                        opacity: 0,
                        position: 'absolute',
                        zIndex: -1,
                      }}
                      onChange={handleChangeCheckbox}
                    />
                    <span
                      id="FullStack"
                      className="checkbox-label w-form-label"
                    >
                      Full Stack Web Engineer
                    </span>
                  </label>
                </div>
                <div className="wp-accordion-item">
                  <label
                    id="FrontEnd-item"
                    htmlFor="frontend"
                    className="w-checkbox wp-accordion-trigger"
                  >
                    <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox"></div>
                    <input
                      type="checkbox"
                      id="frontend"
                      name="frontend"
                      data-name="frontend"
                      readOnly={loading}
                      style={{
                        opacity: 0,
                        position: 'absolute',
                        zIndex: -1,
                      }}
                      onChange={handleChangeCheckbox}
                    />
                    <span className="checkbox-label w-form-label">
                      Frontend Developer
                    </span>
                  </label>
                </div>
                <div className="wp-accordion-item">
                  <label
                    id="BackEnd-item"
                    htmlFor="backend"
                    className="w-checkbox wp-accordion-trigger"
                  >
                    <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox"></div>
                    <input
                      type="checkbox"
                      id="backend"
                      name="backend"
                      data-name="backend"
                      readOnly={loading}
                      style={{
                        opacity: 0,
                        position: 'absolute',
                        zIndex: -1,
                      }}
                      onChange={handleChangeCheckbox}
                    />
                    <span className="checkbox-label w-form-label">
                      Backend Developer
                    </span>
                  </label>
                </div>
                <div className="wp-accordion-item">
                  <label
                    id="DevOps-item"
                    htmlFor="devops"
                    className="w-checkbox wp-accordion-trigger"
                  >
                    <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox"></div>
                    <input
                      type="checkbox"
                      id="devops"
                      name="devops"
                      data-name="devops"
                      readOnly={loading}
                      style={{
                        opacity: 0,
                        position: 'absolute',
                        zIndex: -1,
                      }}
                      onChange={handleChangeCheckbox}
                    />
                    <span className="checkbox-label w-form-label">
                      DevOps Engineer
                    </span>
                  </label>
                </div>
                <div className="wp-accordion-item">
                  <label
                    id="UX-UI-item"
                    htmlFor="uiux"
                    className="w-checkbox wp-accordion-trigger"
                  >
                    <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox"></div>
                    <input
                      type="checkbox"
                      id="uiux"
                      name="uiux"
                      data-name="uiux"
                      readOnly={loading}
                      style={{
                        opacity: 0,
                        position: 'absolute',
                        zIndex: -1,
                      }}
                      onChange={handleChangeCheckbox}
                    />
                    <span className="checkbox-label w-form-label">
                      UX/UI Designer
                    </span>
                  </label>
                </div>
                <div className="wp-accordion-item">
                  <label
                    id="ProjectManager-item"
                    htmlFor="projectmanager"
                    className="w-checkbox wp-accordion-trigger"
                  >
                    <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox"></div>
                    <input
                      type="checkbox"
                      id="projectmanager"
                      name="projectmanager"
                      data-name="projectmanager"
                      readOnly={loading}
                      style={{
                        opacity: 0,
                        position: 'absolute',
                        zIndex: -1,
                      }}
                      onChange={handleChangeCheckbox}
                    />
                    <span className="checkbox-label w-form-label">
                      Project Manager
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div> */}
          <div className="form-privacy">
            <label
              htmlFor="Privacy-Policy"
              className="w-checkbox wp-checkbox privacypolicy"
            >
              <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox privacypolicy"></div>
              <input
                type="checkbox"
                id="Privacy-Policy"
                name="Privacy-Policy"
                data-name="Privacy Policy"
                readOnly={loading}
                required
                style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                onChange={handleChangeCheckbox}
              />
              <span className="caption w-form-label">
                By submitting this form, I consent to Mosano collecting my
                information for marketing and commercial purposes in accordance
                with the <Link to="/privacy-policy">Privacy Policy</Link>. I
                understand that I can unsubscribe or withdraw my consent at any
                time.
                <strong></strong>
                <br />
              </span>
            </label>
          </div>
          <input
            type="submit"
            value="Send Message"
            data-wait="Please wait..."
            id="tracking-button"
            className="button form w-button"
            disabled={loading}
          />
        </form>
        <div className="success-message w-form-done">
          <div className="div-block-47 w-clearfix">
            <div className="text-block-7">
              Thank you! Your submission has been received!
            </div>
            <p className="paragraph2 center">
              Now visit our website to get to know everything about us.
            </p>
            <a
              href="https://mosano.eu/"
              target="_blank"
              rel="noopener noreferrer"
              className="button website w-button"
            >
              Visit Website
            </a>
          </div>
        </div>
        <div className="error-message w-form-fail">
          <div className="text-block-8">
            Oops! Something went wrong while submitting the form.
          </div>
        </div>
      </div>
    </div>
  );
}
