import clsx from 'clsx';
import * as React from 'react';

export interface FadeInProps
  extends React.ComponentPropsWithoutRef<'section'> {}

export default function FadeIn({ className, children, ...props }: FadeInProps) {
  const elementRef = React.useRef<HTMLElement | null>(null);
  const [isVisible, setIsVisible] = React.useState<boolean>(false);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target); // Stop observing once visible
        }
      },
      { threshold: 0.2 }, // Trigger when 20% of the component is visible
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }
    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  return (
    <section
      ref={elementRef}
      className={clsx(
        'fade-in-section',
        isVisible && 'section-is-visible',
        className,
      )}
      {...props}
    >
      {children}
    </section>
  );
}
