import * as React from 'react';

import clsx from 'clsx';

export interface SocialsProps extends React.ComponentPropsWithoutRef<'div'> {}

export default function Socials({ className, ...props }: SocialsProps) {
  return (
    <div className={clsx('wp-socialmedia', className)} {...props}>
      <a
        href="https://github.com/mosano-eu?utm_source=github&amp;utm_medium=landingpage&amp;utm_campaign=teamexpansion&amp;utm_content=logo"
        target="_blank"
        rel="noopener noreferrer"
        className="wp-socialmedia-icon1 w-inline-block"
      >
        <img
          src="/images/Github.svg"
          loading="lazy"
          alt="Github"
          className="github"
        />
        <div className="w-embed">
          <input
            type="hidden"
            className="utm_source"
            placeholder="utm_source"
            name="utm_source"
          />
          <input
            type="hidden"
            className="utm_medium"
            placeholder="utm_medium"
            name="utm_medium"
          />
          <input
            type="hidden"
            className="utm_campaign"
            placeholder="utm_campaign"
            name="utm_campaign"
          />
        </div>
        <img
          src="/images/Github_1.svg"
          loading="lazy"
          alt="Github"
          className="github-hover"
        />
      </a>
      <a
        href="https://gitlab.com/mosano"
        target="_blank"
        rel="noopener noreferrer"
        className="wp-socialmedia-icon2 w-inline-block"
      >
        <img
          src="/images/Gitlab.svg"
          loading="lazy"
          alt="GitLab"
          className="gitlab"
        />
        <img
          src="/images/Gitlab_1.svg"
          loading="lazy"
          alt="GitLab"
          className="gitlab-hover"
        />
      </a>
      <a
        href="https://www.linkedin.com/company/mosano/"
        target="_blank"
        rel="noopener noreferrer"
        className="wp-socialmedia-icon3 w-inline-block"
      >
        <img
          src="/images/LinkedIn.svg"
          loading="lazy"
          alt="LinkedIn"
          className="linkedin"
        />
        <img
          src="/images/LinkedIn_1.svg"
          loading="lazy"
          alt="LinkedIn"
          className="linkedin-hover"
        />
      </a>
      <a
        href="https://www.instagram.com/mosano.eu/"
        target="_blank"
        rel="noopener noreferrer"
        className="w-inline-block"
      >
        <img
          src="/images/Insta-Icon.svg"
          loading="lazy"
          alt="Instagram"
          className="insta"
        />
      </a>
      <a
        href="http://mosano.eu/?utm_source=website&amp;utm_medium=landingpage&amp;utm_campaign=teamexpansion&amp;utm_content=logo"
        target="_blank"
        rel="noopener noreferrer"
        className="wp-socialmedia-icon5 w-inline-block"
      >
        <img
          src="/images/Group-7.svg"
          loading="lazy"
          alt="Mosano"
          className="site"
        />
        <img
          src="/images/Facebook_1.svg"
          loading="lazy"
          alt="Mosano"
          className="site-hover"
        />
      </a>
    </div>
  );
}
