import * as React from 'react';

import clsx from 'clsx';
import { Link } from 'gatsby';

export interface HeaderProps extends React.ComponentPropsWithoutRef<'header'> {}

export default function Header({ className, ...props }: HeaderProps) {
  return (
    <header
      data-collapse="small"
      data-animation="default"
      data-duration="400"
      data-doc-height="1"
      data-easing="ease"
      data-easing2="ease"
      role="banner"
      className={clsx('navbar w-nav', className)}
      {...props}
    >
      <div className="wp-navbnar">
        <Link
          to="/"
          aria-current="page"
          className="logo w-nav-brand w--current"
        >
          <img
            src="/images/Logo.png"
            loading="lazy"
            width="126"
            height="24"
            alt="Mosano Logo"
            className="logo-img"
          />
        </Link>
        {/* <nav role="navigation" className="wp-menu w-clearfix w-nav-menu">
          <Link to="#choose-your-team" className="navlink current w-nav-link">
            Choose Your Team
          </Link>
          <Link to="#projects" className="navlink current w-nav-link">
            Projects
          </Link>
          <Link to="#testimonials" className="navlink current w-nav-link">
            Testimonials
          </Link>
          <Link
            to="#contact-form"
            id="work-button-header"
            className="button w-button"
          >
            Let's Talk
          </Link>
        </nav> */}
      </div>
    </header>
  );
}
