import * as React from 'react';

import clsx from 'clsx';
import { Link } from 'gatsby';

import Socials from './socials';

export interface FooterProps extends React.ComponentPropsWithoutRef<'footer'> {}

export default function Footer({ className, ...props }: FooterProps) {
  return (
    <footer className={clsx('s-footer wf-section', className)} {...props}>
      <div className="mycontainer footer">
        <div className="footer-row">
          <div className="wp-copyright">
            <div className="div-block-46"></div>
            <div className="text-block-5">
              {new Date().getFullYear()} Mosano
            </div>
          </div>
          <Socials style={{ margin: 0 }} />
          <Link to="/privacy-policy" className="linkfooter">
            Privacy Policy
          </Link>
        </div>
      </div>
    </footer>
  );
}
