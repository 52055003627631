import * as React from 'react';

import Header from './header';
import Footer from './footer';

export interface LayoutProps extends React.PropsWithChildren {
  header?: boolean;
  footer?: boolean;
}

export default function Layout({
  header = true,
  footer = true,
  children,
}: LayoutProps) {
  return (
    <React.Fragment>
      {header && <Header style={{ flexShrink: 0 }} />}
      <main style={{ flexGrow: 1 }}>{children}</main>
      {footer && <Footer style={{ width: '100%', flexShrink: 0 }} />}
    </React.Fragment>
  );
}
