import * as React from 'react';

import emailjs from '@emailjs/browser';
import type { WrapPageElementBrowserArgs } from 'gatsby';

import 'react-multi-carousel/lib/styles.css';
import './static/css/normalize.css';
import './static/css/webflow.css';
import './static/css/mosano-team-expansion.webflow.css';

import { Layout } from './src/components/shared';

export const onClientEntry = () => {
  emailjs.init('user_xYVRZdMhAI6rqnPpzv3uE');
};

export const wrapPageElement = ({
  element,
  props,
}: WrapPageElementBrowserArgs) => {
  return <Layout footer={!props.path.includes('/404')}>{element}</Layout>;
};
